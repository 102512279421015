<script setup lang="ts">
const { products } = await useSearch({
  ignoreParamsFromUrl: true,
  sortMethod: [
    'variants.attributes.dspn-car desc',
    'variants.attributes.follower_count_numeric desc',
  ],
})
</script>

<template>
  <section>
    <GigCarousel :items="products">
      <div class="mb-7 flex items-center justify-between">
        <h2 class="text-2xl text-gray-100">
          Get Inspired by our Popular Collabs
        </h2>
        <BaseSeeAllLink
          url="/search/all"
          class="hidden md:block"
          aria-label="See all popular Collabs"
        />
      </div>
    </GigCarousel>
    <div class="mt-8 md:hidden">
      <BaseSeeAllLink
        url="/search/all"
        aria-label="See all popular Collabs"
      />
    </div>
  </section>
</template>
